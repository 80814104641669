.topic-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid #cac5c5;
  background-color: #fff;
  align-items: center;
  padding-bottom: 20px;
  height: 500px;
}

.topic-front {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  width: 100%;
  height: 100%;
}

.topic-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 26px;
  width: 100%;
}

.topic-back {
  display: none;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.topic-back-mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.topic-front img {
  width: 100%;
  height: 207px;
  object-fit: cover;
}

.topic-container p {
  font-size: 27px;
  font-weight: 700;
  color: #000;
  text-align: center;
}

.topic-description {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: Roboto;
  color: #000;
  padding: 10px;
  text-align: center;
}

.topic-container .topic-title {
  font-size: 27px;
  line-height: 27px;
  font-weight: 700;
  color: #000;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
}

.topic-container button {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border-radius: 32px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #ba61ff;
  border: #ba61ff 1px solid;
}

.topic-container button:hover {
  color: #000;
  background-color: #fff;
  border: #000 1px solid;
}

.topic-front-button-mobile {
  display: none;
}

.topic-front-button-desktop {
  display: block;
}

@media (max-width: 1920px) {
  .topic-container {
    height: 400px;
  }
}

@media (max-width: 1440px) {
  .topic-container {
    height: 480px;
  }
}

@media (max-width: 1199.98px) {
  .topic-container {
    height: 500px;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
}

@media (max-width: 575.98px) {
  .topic-container {
    padding-bottom: 0;
    height: 280px;
  }
  .topic-title {
    display: none;
  }
  .topic-description {
    margin-top: 10px;
  }
  .topic-container img {
    display: none;
  }
  .topic-container p {
    padding-top: 0;
  }
  .topic-front {
    padding: 35px;
  }
  .topic-front button {
    margin-top: 20px;
  }
  .topic-content {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
  }
  .topic-back button {
    margin-bottom: 20px;
  }
  .topic-front-button-mobile {
    display: block;
  }

  .topic-front-button-desktop {
    display: none;
  }
}
