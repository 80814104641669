#demo-header-bg {
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vw;
}

#demo-header-bg-mask {
  display: flex;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.demo-header-content {
  display: flex;
  flex-direction: column;
  width: 25%;
  gap: 24px;
  margin-left: 16vw;
}

.demo-header-title {
  font-size: 39px;
  line-height: 47px;
  font-weight: 700;
  text-align: left;
  color: #fff;
}

.demo-header-arrow-down {
  width: 32px;
  height: 42px;
  /* animation loop up and down */
  animation: arrow-down 1.5s ease-in-out infinite;
}

#demo-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
}

.demo-iframe-content {
  width: 100%;
  height: 1200px;
  border: 1px solid #e5e5e5;
}

.demo-iframe-video {
  width: 100%;
}

.demo-page {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.demo-page-padding {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding-left: 16vw;
  padding-right: 16vw;
  padding-bottom: 40px;
}

.demo-title {
  color: #000;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Inter;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.demo-text {
  color: #000;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.demo-text-2 {
  color: #000;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.demo-hide-section {
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
}

#starRatingThing {
  font-size: 46px;
  color: #9747ff;
}

.demo-form-padding {
  padding-top: 68px;
  padding-bottom: 113px;
}

@media (max-width: 1199.98px) {
  .demo-header-title {
    font-size: 30px;
    line-height: normal;
  }
}

@media (max-width: 991.98px) {
  #demo-header-bg {
    height: 40vw;
  }
}

@media (max-width: 767.98px) {
  #demo-header-bg {
    height: 50vw;
  }
}

@media (max-width: 575.98px) {
  .demo-header-content {
    margin-left: 10vw;
  }
  .demo-header-title {
    font-size: 24px;
  }
  .demo-text {
    font-size: 18px;
    line-height: normal;
  }
  .demo-page-padding {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  .demo-form-padding {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 34px;
    padding-bottom: 34px;
  }
}
