header {
  display: block;
  height: 70vh;
  min-height: 550px;
}

#header-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

#header-bg-mask {
  display: flex;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
}

.header-title {
  position: relative;
  left: 8%;
  width: 70%;
  height: 25%;
  font-size: 60px;
  font-weight: 700;
  line-height: 80px;
  text-align: left;
  color: #fff;
}

.header-subtitle {
  position: relative;
  left: 8%;
  width: 30%;
  font-size: 39px;
  font-weight: 700;
  line-height: 39px;
  text-align: left;
  color: #ba61ff;
}

.header-topics {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 8%;
  width: 50%;
}

.header-text {
  position: relative;
  left: 8%;
  width: 30%;
}

.header-content {
  position: relative;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #fff;
}

.header-arrow-down {
  position: relative;
  left: 8%;
  margin-top: 2%;
  width: 32px;
  height: 42px;
  /* animation loop up and down */
  animation: arrow-down 1.5s ease-in-out infinite;
}

@keyframes arrow-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.scrolling-text {
  overflow: hidden;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  background-color: #ffffff;
}

.scrolling-text p {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  color: #ba61ff;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  #header-bg-mask {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .header-title {
    font-size: 48px;
    line-height: 48px;
  }
  .header-subtitle {
    font-size: 30px;
    line-height: 30px;
  }
  .header-content {
    font-size: 18px;
    line-height: 18px;
  }
  .scrolling-text {
    height: 18px;
  }
  .scrolling-text > div p {
    font-size: 18px;
    line-height: 18px;
  }
}

@media (max-width: 767.98px) {
  .header-title {
    font-size: 42px;
    line-height: 42px;
    height: 26%;
  }
  .header-subtitle {
    font-size: 24px;
    line-height: 24px;
  }
  .header-content {
    font-size: 16px;
    line-height: 16px;
  }
  .scrolling-text {
    height: 16px;
  }
  .scrolling-text > div p {
    font-size: 16px;
    line-height: 16px;
  }
}

@media (max-width: 575.98px) {
  header {
    height: 100vw;
  }
  .header-title {
    font-size: 30px;
    line-height: 30px;
  }
  .header-subtitle {
    font-size: 18px;
    line-height: 20px;
  }
  .header-content {
    font-size: 14px;
    line-height: 14px;
  }
  .scrolling-text {
    height: 14px;
  }
  .scrolling-text > div p {
    font-size: 14px;
    line-height: 14px;
  }
}
