.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.home-title {
  font-size: 26px;
  font-weight: 700;
  color: #000;
  padding: 48px;
  margin-top: 30px;
  text-align: center;
}

.show-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  gap: 20px;
  padding: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.topics-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  gap: 36px;
  padding-top: 0px;
  padding-bottom: 10%;
  padding-left: 20%;
  padding-right: 20%;
  width: 100%;
  justify-content: center;
}

.show-more:hover {
  opacity: 0.8;
}

.show-more span {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

@media (max-width: 1199.98px) {
  .topics-grid-container {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (max-width: 991.98px) {
  .topics-grid-container {
    gap: 30px;
    padding-top: 30px;
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (max-width: 767.98px) {
  .topics-grid-container {
    grid-template-columns: repeat(2, 50%);
    gap: 20px;
    padding-top: 0px;
    padding-left: 10%;
    padding-right: 10%;
  }
  .home-title {
    font-size: 26px;
    padding: 24px;
    margin-top: 0px;
  }
}

@media (max-width: 575.98px) {
  .topics-grid-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding-top: 0px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .home-title {
    font-size: 20px;
    padding: 24px;
    margin-top: 0px;
  }
}
