.email-header-container {
  height: 363px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  line-height: normal;
}

.email-header-container > div {
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.email-header-container p {
  font-size: 40px;
  font-weight: 700;
  color: #000;
  text-align: left;
  margin-left: 10%;
  width: 30%;
}

.email-content-container {
  display: flex;
  flex-direction: row;
  gap: 6%;
  padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 11%;
  padding-right: 11%;
  justify-content: center;
}

.email-card-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 40vw;
  background-color: #fff;
  padding: 36px;
  border: 1px solid #cac5c5;
  border-radius: 6px;
  gap: 24px;
}

.email-list-container {
  display: flex;
  width: 40vw;
  align-items: center;
}

.email-input {
  width: 100%;
  height: 36px;
  border-radius: 3px;
  padding-left: 1vw;
  background: #e6e6e6;
  outline: none;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #787676;
}

.form-checkbox-container {
  display: flex;
}

.form-checkbox-label {
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  text-align: left;
  color: #121419;
  margin-left: 4px;
  white-space: initial;
  line-height: 24px;
}

.form-text-muted {
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  text-align: left;
  color: #696974;
  line-height: 24px;
}

.link {
  color: #3c3cd1;
  text-decoration-line: underline;
}

.email-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 17px;
  padding-top: 1vw;
  padding-bottom: 1vw;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.highlight {
  color: #ba61ff;
}

@media (max-width: 991.98px) {
  .email-header-container p {
    font-size: 36px;
    width: 50%;
  }
  .email-content-container {
    gap: 24px;
    padding-top: 36px;
    padding-bottom: 36px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .email-card-container {
    gap: 24px;
    padding: 24px;
  }
}

@media (max-width: 767.98px) {
  .email-header-container p {
    font-size: 34px;
    width: 50%;
  }
  .email-list-item {
    line-height: normal;
  }
  .email-icon-tick {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 575.98px) {
  .email-header-container > div {
    align-items: flex-start;
  }
  .email-header-container p {
    font-size: 30px;
    color: #fff;
    width: 60%;
    margin-top: 54px;
    margin-left: 30px;
  }
  .email-content-container {
    flex-direction: column;
    gap: 36px;
    padding-top: 36px;
    padding-bottom: 36px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .email-card-container {
    width: 100%;
    gap: 12px;
    padding: 24px;
  }
  .email-input {
    line-height: normal;
  }
  .form-text-muted {
    line-height: normal;
  }
  .email-list-container {
    width: 100%;
  }
  .email-icon-tick {
    width: 36px;
    height: 36px;
  }
  .email-list-item {
    line-height: normal;
  }
}
