@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  image-rendering: optimizeQuality;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-branded {
  font-weight: 700;
  font-size: 16px;
  font-family: Roboto;
  text-transform: capitalize;
  color: white;
  background: #ba61ff;
  border: 1px solid #ba61ff;
  padding: 6px 12px;
  border-radius: 32px;
}

.btn-branded:hover {
  border: 1px black solid;
  background: white;
  color: black;
}
